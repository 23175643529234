<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Details of product {{ product.number }}
                </CCardTitle>
                <div class="card-header-actions"></div>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput name="number" label="Number" placeholder="Enter the number of the product"
                      v-model="product.number" />
                  </CCol>
                  <CCol sm="6">
                    <CSelect v-if="$store.getters.isAdmin" placeholder="Select" label="Location" :options="locations"
                      :value.sync="product.location_id" />
                    <CInput v-if="!$store.getters.isAdmin" name="location_id" type="hidden"
                      v-model="product.location_id" />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol sm="6">
                    <CSelect label="Type" :options="[
                      { label: 'Bicycle', value: 'bicycle' },
                      { label: 'Accessories', value: 'accessories' },
                      { label: 'E-bike', value: 'e-bike' },
                    ]" :value.sync="product.type" />
                  </CCol>
                  <CCol sm="6">
                    <CSelect v-if="$store.getters.isAdmin" label="State" :options="[
                      { label: 'Correct', value: 'correct' },
                      { label: 'Broken', value: 'broken' },
                      { label: 'Lost', value: 'lost' },
                      { label: 'Basement', value: 'basement' },
                    ]" :value.sync="product.state" />
                    <CInput v-else name="state" label="State" placeholder="" v-model="product.state" disabled />
                  </CCol>
                  <CCol sm="12">
                    <CTextarea name="note" placeholder="Enter a note"
                      description="Enter a note for the product. E.g. required reparation information."
                      v-model="product.note" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Additional Information</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="12">
                    <CButton v-if="$store.getters.isAdmin" variant="outline" type="submit" color="warning"
                      @click="addCustomField()">
                      <CIcon name="cil-playlist-add" /> New Field
                    </CButton>
                  </CCol>

                  <br />

                  <CContainer v-for="(custom_field, index) in product.custom_fields" id="customFieldsContainer">
                    <CRow>
                      <CCol sm="3">
                        <CInput name="name" label="Name" placeholder="Enter the name"
                          v-model="product.custom_fields[index].name" :disabled="custom_field.id" />
                      </CCol>
                      <CCol sm="9">
                        <CInput name="value" label="Value" placeholder="Enter the value"
                          v-model="product.custom_fields[index].value" />
                      </CCol>
                    </CRow>
                  </CContainer>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol>
            <CButton variant="outline" type="submit" color="primary" @click="updateProduct">
              <CIcon name="cil-check-circle" /> Update
            </CButton>
            <CButton v-if="$store.getters.isAdmin" class="float-right" variant="outline" type="submit" color="danger"
              @click="deleteProduct">
              <CIcon name="cil-trash" /> Delete
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import ProductAPI from "/app/src/api/product.js"
import LocationAPI from "/app/src/api/location.js"

export default {
  name: "Detail",
  data: () => {
    return {
      product: {},
      locations: [],
      productAPI: new ProductAPI(),
      locationAPI: new LocationAPI(),
    }
  },
  created: function () {
    this.getLocations()
    this.getProduct(this.$route.params.id)
  },
  methods: {
    getLocations: function () {
      this.$store.dispatch("loading")
      let self = this
      self.locationAPI
        .all()
        .then((result) => {
          this.$store.dispatch("stopLoading")
          for (let i = 0; i < result.length; i++) {
            self.locations.push({ label: result[i].name, value: result[i].id })
          }
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          console.log(error)
          this.$alert.show({ type: "danger", message: error })
        })
    },
    getProduct: function (id) {
      this.$store.dispatch("loading")
      let self = this
      self.productAPI
        .detail(id)
        .then((product) => {
          this.$store.dispatch("stopLoading")
          console.log(product)
          self.product = product
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          console.log(error)
          this.$alert.show({ type: "danger", message: error })
        })
    },
    updateProduct: function (id) {
      this.$store.dispatch("loading")
      console.log(this.product)
      let self = this
      this.productAPI
        .update(self.product)
        .then((result) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({
            type: "success",
            message: "Product has been updated",
          })
        })
        .catch((error) => {
          console.log(error)
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
        })
    },
    deleteProduct: function (id) {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("loading")
          this.productAPI
            .delete(this.$route.params.id)
            .then((result) => {
              this.$store.dispatch("stopLoading")
              console.log(result)
              this.$router.push({ path: "/manager/products" })
            })
            .catch((error) => {
              this.$store.dispatch("stopLoading")
              console.log(error)
              this.$alert.show({ type: "danger", message: error })
            })
        },
      }
      this.$confirm.show(params)
    },
    addCustomField: function () {
      this.product.custom_fields.push({ name: "", value: "" })
    },
  },
}
</script>
